import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { createSortDescription } from 'Components/Table/createColumns';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { axiosClient } from 'Services/Api/client';
import DASHBOARD_API from 'Services/Api/Dashboard/Api';
import {
	ElearningGroupSummary,
	ElearningGroupSummaryRequest,
	OverviewGroupSummary,
	PhishingActiveCampaign,
	PhishingActiveCampaignsRequest,
	PhishingTotalCampaignOverview,
	PhishingGroupSummary,
	PhishingGroupSummaryParams,
	SpotCheckOverviewResponse,
	SpotCheckOverviewParams,
	SpotCheckICIResponse,
	SpotCheckGroupSummaryParams,
	SpotCheckGroupSummaryResponse,
	MyResultsResponse,
	OverviewScores,
	OverviewGroupSummaryRequest,
	PhishingTotalCampaignScore,
	DashboardRequestBase,
	ElearningOverview,
	ElearningPathSummary,
	ElearningTotalScore,
	ElearningCourseActivity,
	HistoricalCompletedData,
	CampainSummaryData
} from 'Services/Api/Dashboard/Types';
import {
	Elearning,
	Overview,
	Phishing,
	SpotCheck
} from './DashboardStore.types';
import { Status } from 'app-types';
import { getSpotCheckSummaryOverviewData } from '../util';
import { useHistory } from 'react-router';
import { getAppRoutes } from 'Pages/App/App.constants';

interface ApiCreds{
	[key: string]:any
}
let apiOverviewScores = {
	"phishingScore": {
		"current": null,
		"hystorical": null
	},
	"spotCheckScore": {
		"current": null,
		"hystorical": null
	},
	"elearningScore": {
		"current": null,
		"hystorical": null
	},
	"totalIndexComposite": {
		"current": null,
		"hystorical": null
	},
	"properties": null,
	"numberOfUsers": null
} as any;

let apiGroupSummary = {
	"phishingScore": null,
	"elearningScore": null,
	"spotCheckScore": null,
	"id": null,
	"groupName": null,
	"indexComposite": null
} as any;

let apiMyResults = {
	"id": null,
	"systemId": null,
	"userId": null,
	"phishingScore": null,
	"elearningScore": null,
	"spotCheckScore": null,
	"coursesRemaining": null,
	"date": null,
	"updatedDate": null
} as any

let apiElearningScore = {
	"riskScore": null,
	"enrollments": null,
	"users": null,
} as any

let apiElearningPathSummary = {
	"percentageComplete": null,
    "learningPathId": null,
    "learningPathName": null
} as any

let apiElearningOverview = {
	"complete":null,
	"inProgress":null,
	"notStarted":null
} as any

let apiElearningCourseActivity = {
	"lastYearResults": {
        "totalCompleted": null,
        "certified": null,
        "selfTraining": null,
        "totalEnrollments": null
    },
    "last90DaysResults": {
        "totalCompleted": null,
        "certified": null,
        "selfTraining": null,
        "totalEnrollments": null
    }
} as any

let apiElearningCompletedCourses = {
	"date": 0,
	"value": 0
} as any

let apiElearningGroupSummary = {
	"complete": null,
    "inProgress": null,
    "notStarted": null,
    "enrollments": null,
    "score": null,
    "users": null,
    "groupName": null,
    "id": null
} as any

let apiPhishingTotalCampaignOverview = {
	"campaigns": null,
	"sent": null,
	"clicked": null,
	"opened": null,
	"submitted": null,
	"success": null
} as any

let apiPhishingTotalCampaignScore = {
	"current": null,
	"previous": null,
	"average": null	
} as any

let apiPhishingActiveCampaigns = {
	"name": null,
    "date": null,
    "email": null,
    "landing": null,
    "status": null,
    "score": null
} as any

let apiPhishingCampaignSummary = {
	"current": {
        "sent": null,
        "clicked": null,
        "emailsOpened": null,
        "submitted": null,
        "attachmentsOpened": null,
        "success": null
    },
    "previous": {
        "sent": null,
        "clicked": null,
        "emailsOpened": null,
        "submitted": null,
        "attachmentsOpened": null,
        "success": null
    }
} as any

let apiPhishingGroupSummary = {
	"id": null,
	"groupName": null,
	"attachmentsOpened": null,
	"dataSubmit": null,
	"emailsClicked": null,
	"emailsOpen": null,
	"score": null,
	"emailsSent": null,
	"success": null,
	"variance": null
} as any

function getAPIcreds(){
	const currentAPIcred: ApiCreds = {};
	const urlhost = window.location.host;
	//let APIurl = 'https://edge2.venzagroup.com/peak';
	let APIurl = 'https://api-stage.venzagrc.com';
	if(!urlhost.includes('ev.') && !urlhost.includes('tg.')){
		//APIurl = 'https://venzapeak.com';
		APIurl = 'https://api-prod.venzagrc.com';
	}
	let i=1;
	let xx=0;
	let yy=0;
	currentAPIcred.userdata = {};
	currentAPIcred.newapitoken = {};
	currentAPIcred.apipath = {"pathcreds":{"path":localStorage.getItem("APIpath"),"pathadmin":localStorage.getItem("APIadmin")}};
	const APIpath = localStorage.getItem("APIpath");
	const APIuseradmin = localStorage.getItem("APIadmin");
	for(i = 0; i < localStorage.length; i++){
		if(localStorage.key(i)?.includes('.userDat')){
			currentAPIcred.userdata[xx] = localStorage.getItem(localStorage.key(i)!);
			xx++;
		}
	}
	for(i = 0; i < localStorage.length; i++){
		/*
		if(localStorage.key(i)?.includes('.idToke')){
			currentAPIcred.newapitoken[yy] = localStorage.getItem(localStorage.key(i)!);
			yy++;
		}
		*/
		if(localStorage.key(i)?.includes('.accessToke')){
			currentAPIcred.newapitoken[yy] = localStorage.getItem(localStorage.key(i)!);
			yy++;
		}
	}
	//const fullAPIurl = APIurl+APIpath+'/middleware/?awsToken='+currentAPIcred.newapitoken[0]+APIuseradmin+'&page=module';
	//const fullAPIurl = 'http://localhost/?awsToken='+currentAPIcred.newapitoken[0]+APIuseradmin+'&page=module';
	const fullAPIurl = APIurl+'/?awsToken='+currentAPIcred.newapitoken[0]+APIuseradmin+'&page=module';
	return fullAPIurl;
}

class DashboardStore {
	// fetch statuses
	status = {
		exportDashboard: Status.Idle,
		getOverviewScores: Status.Idle,
		getOverviewGroupSummary: Status.Idle,

		getPhishingTotalCampaignOverview: Status.Idle,
		getPhishingTotalCampaignScore: Status.Idle,
		getPhishingActiveCampaigns: Status.Idle,
		getPhishingGroupSummary: Status.Idle,
		getPhishingCampaignSummary: Status.Idle,

		getElearningGroupSummary: Status.Idle,
		getElearningOverview: Status.Idle,
		getElearningPathSummary: Status.Idle,
		getElearningScore: Status.Idle,
		getElearningCourseActivity: Status.Idle,
		getElearningCompletedCourses: Status.Idle,

		getSpotCheckOverview: Status.Idle,
		getSpotCheckJobRole: Status.Idle,
		getSpotCheckGroupSummary: Status.Idle,
		getMyResults: Status.Idle
	};

	overview: Overview = {
		groupSummary: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'indexComposite',
					order: 'ascend'
				})
			}
		}
	};

	phishing: Phishing = {
		activeCampaigns: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'name',
					order: 'ascend'
				})
			}
		},
		groupSummary: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'score',
					order: 'ascend'
				})
			}
		}
	};

	elearning: Elearning = {
		groupSummary: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'score',
					order: 'ascend'
				})
			}
		}
	};

	spotCheck: SpotCheck = {
		overview: {
			data: null,
			params: {
				sort: 'spotCheck:current'
			}
		},
		jobRoles: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'industryJobClassName',
					order: 'ascend'
				})
			}
		},
		groupSummary: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'current',
					order: 'ascend'
				})
			}
		}
	};

	// my results data
	myResults?: MyResultsResponse;

	constructor() {
		makeObservable(this, {
			status: observable,
			overview: observable,
			phishing: observable,
			elearning: observable,
			spotCheck: observable
		});
	}

	@action
	async getOverviewScores(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getOverviewScores = Status.Pending;
		});

		try {
			/*
			const response = await axiosClient.get<OverviewScores>(
				DASHBOARD_API.OVERVIEW_SCORES,
				{
					params
				}
			);
			*/
			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					callfor: "widget",
					calltype: "OverviewScores",
					callparams: params
				},
			})
			.then(function (response) {
				apiOverviewScores = response.data as OverviewScores;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.overview.scores = response.data; // original call data
				if(!('response' in apiOverviewScores)){
					const showOverviewScores =  apiOverviewScores;
					this.overview.scores = showOverviewScores;
					this.status.getOverviewScores = Status.Success;
				}else{
					console.log('Failed attempt...');
					//this.status.getOverviewScores = Status.Failure
					this.status.getOverviewScores = Status.Success;
				}
				
			});
		} catch (e) {
			// handle error
			runInAction(() => {
				this.status.getOverviewScores = Status.Failure;
			});

			throw e;
		}
	}

	@action
	async getPhishingTotalCampaignOverview(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getPhishingTotalCampaignOverview = Status.Pending;
		});
		try {
			/*
			const response = await axiosClient.get<PhishingTotalCampaignOverview>(
				DASHBOARD_API.PHISHING_OVERVIEW,
				{ params }
			);
			*/
			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					callfor: "widget",
					calltype: "PhishingTotalCampaignOverview",
					callparams: params
				},
			})
			.then(function (response) {
				apiPhishingTotalCampaignOverview = response.data as PhishingTotalCampaignOverview;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.phishing.overview = response.data;
				if(!('response' in apiPhishingTotalCampaignOverview)){
					const showPhishingTotalCampaignOverview = apiPhishingTotalCampaignOverview;
					this.phishing.overview = showPhishingTotalCampaignOverview;
				}
				this.status.getPhishingTotalCampaignOverview = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingTotalCampaignOverview = Status.Failure;
			});
		}
	}

	@action
	async getPhishingTotalCampaignScore(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getPhishingTotalCampaignScore = Status.Pending;
		});
		try {
			/*
			const response = await axiosClient.get<PhishingTotalCampaignScore>(
				DASHBOARD_API.PHISHING_SCORE,
				{
					params
				}
			);
			*/
			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					callfor: "widget",
					calltype: "PhishingTotalCampaignScore",
					callparams: params
				},
			})
			.then(function (response) {
				apiPhishingTotalCampaignScore = response.data as PhishingTotalCampaignScore;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.phishing.score = response.data;
				if(!('response' in apiPhishingTotalCampaignScore)){
					const showPhishingTotalCampaignScore = apiPhishingTotalCampaignScore;
					this.phishing.score = showPhishingTotalCampaignScore;
				}
				this.status.getPhishingTotalCampaignScore = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingTotalCampaignScore = Status.Failure;
			});
		}
	}

	@action
	async getOverviewGroupSummary(params?: OverviewGroupSummaryRequest) {
		runInAction(() => {
			this.status.getOverviewGroupSummary = Status.Pending;
			this.overview.groupSummary.params = {
				...this.overview.groupSummary.params,
				...params
			};
		});
		
		if(params){
			if("isProperty" in params && !("id" in params)){
				if(params.isProperty === false){
					window.location.reload();
					//window.console.log('checking reload removal for decheck');
				}
			}
			if("id" in params){
				if(params.id === undefined){
					window.location.reload();
					//window.console.log('checking reload removal for group deselection');
				}
			}
		}
		
		try {
			/*
			const response = await axiosClient.get<OverviewGroupSummary[]>(
				DASHBOARD_API.OVERVIEW_SUMMARY,
				{ params: this.overview.groupSummary.params }
			);
			*/

			const response = await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					callfor: "widget",
					calltype: "GroupSummary",
					callparams: params
				},
			})
			.then(function (response) {
				apiGroupSummary = response.data as OverviewGroupSummary;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});
			runInAction(() => {
				//this.overview.groupSummary.data = response.data;
				if(!('response' in apiGroupSummary)){
					const showGroupSummary =  apiGroupSummary;
					this.overview.groupSummary.data = showGroupSummary;
					this.status.getOverviewGroupSummary = Status.Success;
				}else{
					console.log('Failed attempt...');
					//this.status.getOverviewScores = Status.Failure
					this.status.getOverviewScores = Status.Success;
				}
			});
		} catch (e) {
			// handle error
			runInAction(() => {
				this.status.getOverviewGroupSummary = Status.Failure;
			});
		}
	}

	@action
	changeOverviewGroupSummarySortParams() {
		/*
		this.overview.groupSummary.params = {
			sort: createSortDescription({
				columnKey: 'groupName',
				order: 'ascend'
			})
		};
		*/
	}

	@action
	async getPhishingActiveCampaigns(
		params?: Partial<PhishingActiveCampaignsRequest>
	) {
		runInAction(() => {
			this.status.getPhishingActiveCampaigns = Status.Pending;
			this.phishing.activeCampaigns.params = {
				...this.phishing.activeCampaigns.params,
				...params
			};
		});

		try {
			/*
			const response = await axiosClient.get<PhishingActiveCampaign[]>(
				DASHBOARD_API.PHISHING_CAMPAIGNS,
				{ params: this.phishing.activeCampaigns.params }
			);
			*/
			const response = await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					callfor: "widget",
					calltype: "PhishingActiveCampaign",
					callparams: params
				},
			})
			.then(function (response) {
				let cleandata = JSON.stringify(response.data);
				cleandata = cleandata.replace(/campaign_launch_/g,"")
				const usedata = cleandata.replace(/campaign_/g,"");
				//apiPhishingActiveCampaigns = response.data as PhishingActiveCampaign;
				apiPhishingActiveCampaigns = JSON.parse(usedata) as PhishingActiveCampaign;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.phishing.activeCampaigns.data = response.data;
				if(!('response' in apiPhishingActiveCampaigns)){
					const showPhishingActiveCampaigns = apiPhishingActiveCampaigns;
					this.phishing.activeCampaigns.data = showPhishingActiveCampaigns;
				}
				this.status.getPhishingActiveCampaigns = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingActiveCampaigns = Status.Failure;
			});
		}
	}

	@action
	async getPhishingGroupSummary(
		params?: Partial<PhishingGroupSummaryParams>
	) {
		runInAction(() => {
			this.status.getPhishingGroupSummary = Status.Pending;
			this.phishing.groupSummary.params = {
				...this.phishing.groupSummary.params,
				...params
			};
		});

		try {
			/*
			const response = await axiosClient.get<PhishingGroupSummary[]>(
				DASHBOARD_API.PHISHING_GROUP_SUMMARY,
				{
					params: this.phishing.groupSummary.params
				}
			);
			*/
			const response = await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					callfor: "widget",
					calltype: "PhishingGroupSummary",
					callparams: params
				},
			})
			.then(function (response) {
				apiPhishingGroupSummary = response.data as PhishingGroupSummary;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.phishing.groupSummary.data = response.data;
				if(!('response' in apiPhishingGroupSummary)){
					const showPhishingGroupSummary = apiPhishingGroupSummary;
					this.phishing.groupSummary.data = showPhishingGroupSummary;
				}
				this.status.getPhishingGroupSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingGroupSummary = Status.Failure;
			});
		}
	}

	@action
	async getPhishingCampaignSummary(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getPhishingCampaignSummary = Status.Pending;
		});
		try {
			/*
			const response = await axiosClient.get<CampainSummaryData>(
				DASHBOARD_API.PHISHING_CAMPAIGN_SUMMARY,
				{
					params
				}
			);
			*/
			const response = await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					callfor: "widget",
					calltype: "PhishingCampaignSummary",
					callparams: params
				},
			})
			.then(function (response) {
				apiPhishingCampaignSummary = response.data as CampainSummaryData;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.phishing.campaignSummary = response.data;
				if(!('response' in apiPhishingCampaignSummary)){
					const showPhishingCampaignSummary = apiPhishingCampaignSummary;
					this.phishing.campaignSummary = showPhishingCampaignSummary;
				}
				this.status.getPhishingCampaignSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingCampaignSummary = Status.Failure;
			});
		}
	}

	@action
	async getElearningCourseActivity(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningCourseActivity = Status.Pending;
		});
		try {
			/*
			const response = await axiosClient.get<ElearningCourseActivity>(
				DASHBOARD_API.ELEARNING_COURSE_ACTIVITY,
				{
					params
				}
			);
			*/
			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					calluser: true,
					callfor: "widget",
					calltype: "ElearningCourseActivity",
					callparams: params
				},
			})
			.then(function (response) {
				apiElearningCourseActivity = response.data as ElearningCourseActivity;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.elearning.courseActivity = response.data;
				if(!('response' in apiElearningCourseActivity)){
					const showElearningCourseActivity = apiElearningCourseActivity;
					this.elearning.courseActivity = showElearningCourseActivity;
				}
				this.status.getElearningCourseActivity = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningCourseActivity = Status.Failure;
			});
		}
	}

	@action
	async getElearningOverview(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningOverview = Status.Pending;
		});
		try {
			/*
			const response = await axiosClient.get<ElearningOverview>(
				DASHBOARD_API.ELEARNING_TOTAL_OVERVIEW,
				{
					params
				}
			);
			*/
			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					calluser: true,
					callfor: "widget",
					calltype: "ElearningOverview",
					callparams: params
				},
			})
			.then(function (response) {
				apiElearningOverview = response.data as ElearningOverview;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.elearning.overview = response.data;
				if(!('response' in apiElearningOverview)){
					const showElearningOVerview = apiElearningOverview
					this.elearning.overview = showElearningOVerview;
				}
				this.status.getElearningOverview = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningOverview = Status.Failure;
			});
		}
	}

	@action
	async getElearningScore(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningScore = Status.Pending;
		});
		try {
			/*
			const response = await axiosClient.get<ElearningTotalScore>(
				DASHBOARD_API.ELEARNING_TOTAL_SCORE,
				{
					params
				}
			);
			*/

			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					calluser: true,
					callfor: "widget",
					calltype: "ElearningScore",
					callparams: params
				},
			})
			.then(function (response) {
				apiElearningScore = response.data as ElearningTotalScore;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.elearning.score = response.data;
				if(!('response' in apiElearningScore)){
					const showElearningScore =  apiElearningScore;
					this.elearning.score = showElearningScore;
				}
				this.status.getElearningScore = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningScore = Status.Failure;
			});
		}
	}

	@action
	async getElearningPathSummary(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningPathSummary = Status.Pending;
		});
		try {
			/*
			const response = await axiosClient.get<ElearningPathSummary[]>(
				DASHBOARD_API.ELEARNING_PATH_SUMMARY,
				{
					params
				}
			);
			*/
			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					calluser: true,
					callfor: "widget",
					calltype: "ElearningPathSummary",
					callparams: params
				},
			})
			.then(function (response) {
				apiElearningPathSummary = response.data as ElearningPathSummary;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.elearning.pathSummary = response.data;
				if(!('response' in apiElearningPathSummary)){
					const showElearningPathSummary = apiElearningPathSummary;
					this.elearning.pathSummary = showElearningPathSummary
				}
				this.status.getElearningPathSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningPathSummary = Status.Failure;
			});
		}
	}

	@action
	async getElearningGroupSummary(params: ElearningGroupSummaryRequest = {}) {
		runInAction(() => {
			this.status.getElearningGroupSummary = Status.Pending;
			this.elearning.groupSummary.params = {
				...this.elearning.groupSummary.params,
				...params
			};
		});

		try {
			/*
			const response = await axiosClient.get<ElearningGroupSummary[]>(
				DASHBOARD_API.ELEARNING_GROUP_SUMMARY,
				{
					params: this.elearning.groupSummary.params
				}
			);
			*/
			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					calluser: true,
					callfor: "widget",
					calltype: "ElearningGroupSummary",
					callparams: this.elearning.groupSummary.params
				},
			})
			.then(function (response) {
				apiElearningGroupSummary = response.data as ElearningGroupSummary;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.elearning.groupSummary.data = response.data;
				if(!('response' in apiElearningGroupSummary)){
					const showElearningGroupSummary = apiElearningGroupSummary;
					this.elearning.groupSummary.data = showElearningGroupSummary;
				}
				this.status.getElearningGroupSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningGroupSummary = Status.Failure;
			});
		}
	}

	@action
	async getElearningCompletedCourses(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningCompletedCourses = Status.Pending;
		});
		try {
			/*
			const response = await axiosClient.get<HistoricalCompletedData>(
				DASHBOARD_API.ELEARNING_COMPLETED_COURSES,
				{
					params
				}
			);
			*/
			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					calluser: true,
					callfor: "widget",
					calltype: "HistoricalCompletedData",
					callparams: params
				},
			})
			.then(function (response) {
				apiElearningCompletedCourses = response.data as HistoricalCompletedData;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.elearning.completedCourses = response.data;
				if(!('response' in apiElearningCompletedCourses)){
					const showElearningCompletedCourses = apiElearningCompletedCourses;
					this.elearning.completedCourses = showElearningCompletedCourses;
				}
				this.status.getElearningCompletedCourses = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningCompletedCourses = Status.Failure;
			});
		}
	}

	@action
	async getSpotCheckOverview(params?: SpotCheckOverviewParams) {
		runInAction(() => {
			this.status.getSpotCheckOverview = Status.Pending;
			this.spotCheck.overview.params = {
				...this.spotCheck.overview.params,
				...params
			};
		});

		try {
			
			const response = await axiosClient.get<SpotCheckOverviewResponse>(
				DASHBOARD_API.SPOT_CHECK_OVERVIEW,
				{
					params: this.spotCheck.overview.params
				}
			);

			runInAction(() => {
				this.spotCheck.overview.data = getSpotCheckSummaryOverviewData(
					response.data
				);
				this.status.getSpotCheckOverview = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getSpotCheckOverview = Status.Failure;
			});
		}
	}

	@action
	async getSpotCheckJobRoleSummary(params?: SpotCheckGroupSummaryParams) {
		runInAction(() => {
			this.status.getSpotCheckJobRole = Status.Pending;
			this.spotCheck.jobRoles.params = {
				...this.spotCheck.jobRoles.params,
				...params
			};
		});

		try {
			
			const response = await axiosClient.get<SpotCheckICIResponse>(
				DASHBOARD_API.SPOT_CHECK_JOB_ROLE,
				{
					params: this.spotCheck.jobRoles.params
				}
			);

			runInAction(() => {
				this.spotCheck.jobRoles.data = response.data;
				this.status.getSpotCheckJobRole = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getSpotCheckJobRole = Status.Failure;
			});
		}
	}

	@action
	async getSpotCheckGroupSummary(params?: SpotCheckGroupSummaryParams) {
		runInAction(() => {
			this.status.getSpotCheckGroupSummary = Status.Pending;
			this.spotCheck.groupSummary.params = {
				...this.spotCheck.groupSummary.params,
				...params
			};
		});
		try {
			
			const response = await axiosClient.get<SpotCheckGroupSummaryResponse>(
				DASHBOARD_API.SPOT_CHECK_SUMMARY,
				{
					params: this.spotCheck.groupSummary.params
				}
			);

			runInAction(() => {
				this.spotCheck.groupSummary.data = response.data;
				this.status.getSpotCheckGroupSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getSpotCheckGroupSummary = Status.Failure;
			});
		}
	}

	@action
	async getMyResults() {
		runInAction(() => {
			this.status.getMyResults = Status.Pending;
		});
		try {
			/*
			const response = await axiosClient.get<MyResultsResponse>(
				DASHBOARD_API.MY_RESULTS
			);
			*/

			await axios({
				method: 'post',
				headers: { 
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				url: getAPIcreds(),
				data: {
					calluser: true,
					callfor: "widget",
					calltype: "MyResults",
					callparams: ""
				},
			})
			.then(function (response) {
				apiMyResults = response.data as MyResultsResponse;
			})
			.catch(function (error) {
				console.log('API ERROR: '+error);
			});

			runInAction(() => {
				//this.myResults = response.data;
				if(!('response' in apiMyResults)){
					const showMyResults =  apiMyResults;
					this.myResults = showMyResults;
				}
				this.status.getMyResults = Status.Success;

			});
		} catch (e) {
			runInAction(() => {
				this.status.getMyResults = Status.Failure;
			});
		}
	}
}

export const dashboardStore = new DashboardStore();
export default DashboardStore;
