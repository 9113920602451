import {
	ControlSpace,
	InfoTooltip,
	Statistic
} from 'Pages/Dashboard/components';
import { Card, Col, Fx, Icon, Row } from 'Components';
import BarChart from 'Pages/Dashboard/visualizations/BarChart/BarChart';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import VisualizationLegend from 'Pages/Dashboard/visualizations/VisualizationLegend/VisualizationLegend';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { getIsPending } from 'Stores/util';
import { prepareChartData } from './CampaignsSummary.helpers';
import {
	VISUALIZATION_COLOR,
	VISUALIZATION_TIME_PERIOD_LABEL
} from 'Pages/Dashboard/visualizations/Visualizations.constants';
import { VisualizationTimePeriod } from 'Pages/Dashboard/visualizations/Visualizations.types';
import { CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP } from './CampaignsSummary.constants';

const CampaignsSummary = () => {
	const { t } = useTranslation();
	const { filters } = useDashboardContext();
	const { dashboardStore } = useStores();
	const {
		status,
		phishing: { campaignSummary }
	} = dashboardStore;

	useEffect(() => {
		dashboardStore.getPhishingCampaignSummary(filters);
		// eslint-disable-next-line
	}, [filters]);

	const chartData = useMemo(() => {
		if (!campaignSummary) return [];
		return prepareChartData(campaignSummary).map((d) => ({
			...d,
			category: t(d.category)
		}));
	}, [campaignSummary, t]);

	return (
		<Card
			title={t('dashboard.tabs.phishing.campaignsSummary.title')}
			extra={
				<ControlSpace>
					<VisualizationLegend
						list={[
							{
								/*
								label: t(
									VISUALIZATION_TIME_PERIOD_LABEL[
										VisualizationTimePeriod.Previous
									]
								),
								*/
								label: t(
									'Previous Campaign'
								),
								color:
									VISUALIZATION_COLOR[
										VisualizationTimePeriod.Previous
									]
							},
							{
								/*
								label: t(
									VISUALIZATION_TIME_PERIOD_LABEL[
										VisualizationTimePeriod.Current
									]
								),
								*/
								label: t(
									'Current Campaign'
								),
								color:
									VISUALIZATION_COLOR[
										VisualizationTimePeriod.Current
									]
							}
						]}
					/>
					<InfoTooltip
						title={t(
							'dashboard.tabs.phishing.campaignsSummary.tooltip'
						)}
					/>
				</ControlSpace>
			}
		>
			<Row>
				<Col xs={18} xxl={16}>
					<BarChart
						data={chartData}
						loading={getIsPending(
							status.getPhishingCampaignSummary
						)}
					/>
				</Col>
				<Col xs={6}>
					<Fx align="middle" justify="even" fullHeight>
						<Statistic
							vertical
							inheritColor
							icon={<Icon type="envelope" />}
							value={campaignSummary?.previous?.sent}
						>
							{t(CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP.sent)}
						</Statistic>
						<Statistic
							type="happy"
							vertical
							inheritColor
							icon={<Icon type="envelope" />}
							value={campaignSummary?.current?.sent}
						>
							{t(CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP.sent)}
						</Statistic>
					</Fx>
				</Col>
			</Row>
		</Card>
	);
};

export default observer(CampaignsSummary);
