import { CampainSummaryDataDetails } from 'Services/Api/Dashboard/Types';

export const CAMPAIGN_SUMMARY_CATEGORIES: (keyof CampainSummaryDataDetails)[] = [
	'emailsOpened',
	'clicked',
	//'attachmentsOpened',
	'submitted',
	'success'
];

export const CAMPAIGN_SUMMARY_CATEGORY_LABEL_MAP: Record<
	keyof CampainSummaryDataDetails,
	string
> = {
	sent: 'dashboard.tabs.phishing.campaignsSummary.emailsSent',
	emailsOpened: 'dashboard.tabs.phishing.campaignsSummary.emailsOpen',
	clicked: 'dashboard.tabs.phishing.campaignsSummary.emailsClicked',
	//attachmentsOpened:
	//	'dashboard.tabs.phishing.campaignsSummary.attachementsOpened',
	submitted: 'dashboard.tabs.phishing.campaignsSummary.dataSubmitted',
	success: 'dashboard.tabs.phishing.campaignsSummary.totalSuccess'
};
