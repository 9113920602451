import { DownloadUrl } from 'app-types';
import { TrendIndicatorDirection } from 'Pages/Dashboard/Dashboard.types';
import { ShowFilterValue } from 'Pages/Dashboard/tabs/SpotCheck/SpotCheck.types';
import { DownloadPdfReportRequest } from '../Reports/Types';

export type DashboardRequestBase = Partial<{
	id: number;
	isProperty: boolean;
}>;

// dashdoard widgets
export interface WidgetData {
	current: number | null;
	hystorical?: number | null;
}

// dashboard tables base
type DashboardTableRequestBase = DashboardRequestBase & {
	sort?: string;
};

// overview widgets
export interface OverviewScores {
	phishingScore: WidgetData;
	spotCheckScore: WidgetData;
	elearningScore: WidgetData;
	totalIndexComposite: WidgetData;
	properties: number | null;
	numberOfUsers: number | null;
}

// overview group summary table
export interface OverviewGroupSummary {
	elearningScore: number;
	groupName: string;
	indexComposite: number;
	phishingScore: number;
	spotCheckScore: number;
}
export type OverviewGroupSummaryRequest = DashboardTableRequestBase;

// Phishing
export interface PhishingTotalCampaignOverview {
	campaigns: number;
	sent: number;
	clicked: number;
	opened: number;
	success: number;
	submitted: number;
}

export interface PhishingTotalCampaignScore {
	current: number | null;
	previous: number | null;
	average: number | null;
}

export interface PhishingActiveCampaign {
	name: string;
	date: string;
	email: string;
	landing: string;
	status: string;
	score: number;
}

export type PhishingActiveCampaignsRequest = DashboardTableRequestBase;
export interface PhishingGroupSummary {
	attachmentsOpened: number;
	dataSubmit: number;
	emailsClicked: number;
	emailsOpen: number;
	emailsSent: number;
	groupName: string;
	score: number;
	variance: number;
	success: number;
}

export interface SpotCheckGroupsOverviewItem {
	groupId: number;
	groupName: string;
	current: number;
	last: number;
	variance: number;
}

export interface SpotCheckJobClassOverviewItem {
	industryJobClassId: number;
	industryJobClassName: string;
	current: number;
	last: number;
	variance: number;
}

export type PhishingGroupSummaryFilterValues = Partial<
	Omit<PhishingGroupSummary, 'id'>
>;
export type PhishingGroupSummaryParams = DashboardTableRequestBase;

// E-Learning
export interface ElearningOverview {
	complete: number | null;
	inProgress: number | null;
	notStarted: number | null;
}
export interface ElearningGroupSummary {
	id: number;
	groupName: string;
	score?: number;
	users?: number;
	enrollments: number;
	complete: number;
	inProgress: number;
	notStarted: number;
}

export interface ElearningTotalScore {
	riskScore: number;
	enrollments: number;
	users: number;
}
export interface ElearningPathSummary {
	learningPathId: number;
	learningPathName: string;
	percentageComplete: number;
}

export interface ElearningCourseActivity {
	last90DaysResults: {
		certified: number | null;
		selfTraining: number | null;
		totalCompleted: number | null;
		totalEnrollments: number | null;
	};
	lastYearResults: {
		certified: number | null;
		selfTraining: number | null;
		totalCompleted: number | null;
		totalEnrollments: number | null;
	};
}

export type ElearningGroupSummaryRequest = DashboardTableRequestBase;

// spot check overview
export interface SpotCheckOverviewResponse {
	ici: number;
	variance: number;
	pas: number;
	tar: number;
	pss: number;
}

export interface SpotCheckOverviewData {
	ICIScore: number;
	changePercent: number;
	trendDirection: TrendIndicatorDirection | null;
	pas: number;
	tar: number;
	pss: number;
}
export type SpotCheckOverviewParams = DashboardRequestBase & {
	sort?: ShowFilterValue;
};

// spot check ici table

export type SpotCheckICIResponse = SpotCheckJobClassOverviewItem[];

// spot check group summary table

export type SpotCheckGroupSummaryParams = DashboardTableRequestBase;
export type SpotCheckJobClassParams = DashboardTableRequestBase;
export type SpotCheckGroupSummaryResponse = SpotCheckGroupsOverviewItem[];

// user activity
export interface UserActivity {
	total: number;
	logged: number;
	lockedOut: number;
	created: number;
	deactivated: number;
	deleted: number;
	duration: number;
}
export type GetUsersActivityRequest = DashboardRequestBase;
export type GetUsersActivityResponse = {
	getUsersActivity: UserActivity;
};

export enum ExportUsersActivityFileType {
	Csv = 'csv',
	Xlsx = 'xlsx'
}
export interface ExportUsersActivityRequest {
	groupIds?: number[];
	ownershipGroupIds?: number[];
	isProperty?: boolean;
	fileType: ExportUsersActivityFileType;
}
export interface ExportUsersActivityResponse {
	exportUsersActivity: DownloadUrl;
}

export interface CampainSummaryDataDetails {
	//attachmentsOpened: number | null;
	clicked: number | null;
	emailsOpened: number | null;
	sent: number | null;
	submitted: number | null;
	success: number | null;
}

export interface CampainSummaryData {
	current: CampainSummaryDataDetails | null;
	previous: CampainSummaryDataDetails | null;
}

//	My Results
export interface MyResultsResponse {
	coursesRemaining: number;
	elearningScore: number;
	phishingScore: number;
	spotCheckScore: number;
}

export type HistoricalCompletedData = { date: string; value: number }[];

export enum DashboardTabKey {
	Overview = 'overview',
	Phishing = 'phishing',
	Elearning = 'eLearning',
	SpotCheck = 'spotCheck',
	UserActivity = 'userActivity',
	MyResults = 'myResults'
}

export type DownloadExecutiveSummaryReportRequest = DashboardRequestBase &
	DownloadPdfReportRequest & {
		tabs: DashboardTabKey[];
	};

export type DownloadRiskScoreCardReportRequest = DashboardRequestBase &
	DownloadPdfReportRequest;
